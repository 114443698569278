@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.gn-landingpage-wrapper {
  background-image: linear-gradient(to bottom, #4a4e69, #c9ada7);
  padding-bottom: 100px;
}

.gn-landingpage-wrapper h1,
h2 {
  margin: 0;
}

.gn-landingpage-wrapper section {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.global-news-title {
  margin-top: 90px;
  font-weight: 400;
  font-size: 31.25px;
  color: #f2e9e4;
}

.gn-copy-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex-direction: column;
}

.gn-main-copy {
  font-weight: 400;
  font-size: 39.06px;
  color: #f2e9e4;
}

.gn-sub-copy {
  font-weight: 400;
  font-size: 25px;
  color: #f2e9e4;
}

.gn-main-copy {
  padding-top: 30px;
}

.gn-google-link {
  background-color: #4a4e69;
  padding: 8px;
  border-radius: 32px;
  border: 2px solid #f2e9e4;
  font-weight: 500;
  letter-spacing: -1px;
  cursor: pointer;
  margin-left: 5px;
  color: white;
}

.gn-graphic {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.lndpg-btns-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 30px;
}

.lndpg-btns-wrapper button {
  background-color: rgb(74, 78, 105);
  border: 2px solid white;
  color: white;
  border-radius: 20px;
  padding: 10px 20px;
  width: 100px;
  font-size: 16px;
  cursor: pointer;
}

.ellipse {
  height: 400px;
  width: 400px;
  border-radius: 100%;
  border: 1px solid white;
  animation-duration: 10s;
  animation-name: rotation;
  animation-iteration-count: infinite;
  animation-direction: normal;
  background: radial-gradient(
    circle at 100%,
    #53556e,
    #827884 50%,
    #ad989a 75%,
    #70163c 75%
  );
}

.point {
  height: 50px;
  width: 50px;
  background: radial-gradient(
    circle at 100%,
    #827884 50%,
    #827884 45%,
    #22223b
  );
  border-radius: 100%;
  overflow: hidden;
  transform: rotate(180deg);
}

.stem {
  height: 60px;
  width: 5px;
  background-color: #22223b;
  margin-top: -20px;
  margin-left: 20px;
  box-shadow: 20px 10px 20px #22223b;
}

.boxes {
  width: 70%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@media (max-width: 992px) {
  .gn-copy-wrapper {
    width: 600px;
    margin: auto;
  }
  .gn-main-copy {
    width: 80%;
    text-align: center;
  }
  .gn-sub-copy {
    width: 80%;
    text-align: center;
  }
}
@media (max-width: 820px) {
  .gn-copy-wrapper {
    width: 500px;
    margin: auto;
  }
  .gn-main-copy {
    font-size: 31.25px;
  }
  .gn-sub-copy {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .ellipse {
    height: 200px;
    width: 200px;
  }
  .point {
    height: 25px;
    width: 25px;
  }
  .stem {
    height: 32px;
    width: 2.5px;
    margin-left: 11px;
  }
  .gn-landingpage-wrapper {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .gn-landingpage-wrapper section {
    margin-top: 0;
  }
  .global-news-title {
    text-align: center;
    padding-left: 0;
  }
  .gn-main-copy {
    font-size: 25px;
  }
  .gn-sub-copy {
    font-size: 16px;
    color: #fbfbfb;
  }
  .gn-copy-wrapper {
    width: 100%;
  }
}
@media (max-height: 800px) {
  .ellipse {
    height: 300px;
    width: 300px;
  }
}
@media (max-height: 800px) and (max-width: 576px) {
  .ellipse {
    height: 200px;
    width: 200px;
  }
  .point {
    height: 25px;
    width: 25px;
  }
  .stem {
    height: 32px;
    width: 2.5px;
    margin-left: 11px;
  }
}
@media screen and (max-width: 1024px) and (max-height: 768px) {
  .ellipse {
    height: 170px;
    width: 170px;
  }
  .point {
    height: 25px;
    width: 25px;
  }
  .stem {
    height: 32px;
    width: 2.5px;
    margin-left: 11px;
  }
}
.homepage-wrapper {
  height: 100vh;
  background-color: #9a8c98;
  display: grid;
  grid-template-columns: 50% 50%;
}

.left-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #9a8c98;
}

.GN-title {
  color: #f2e9e4;
  font-weight: 400;
  font-size: 31.25px;
  margin: 0;
  padding: 40px;
  position: absolute;
  left: -5px;
}

.savedplaces-component-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.locations-dropdwn-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #22223b;
  color: #f2e9e4;
  font-weight: 400;
  font-size: 25px;
  width: 400px;
  padding: 5%;
  border-radius: 32px;
  cursor: pointer;
}

.savedplaces-list-closed {
  list-style-type: none;
  background-color: #22223b;
  width: 350px;
  text-align: center;
  padding: 0;
  margin-top: 0;
  border-radius: 6px 6px 12px 12px;
  height: 100%;
  transition: height 1s;
  max-height: 0;
  margin: 0;
  transition: max-height 0.1s ease-out;
  overflow: hidden;
}

.savedplaces-list {
  list-style-type: none;
  background-color: #22223b;
  width: 350px;
  text-align: center;
  padding: 0;
  margin-top: 0;
  border-radius: 6px 6px 12px 12px;
  height: 100%;
  transition: height 1s;
  max-height: 1000px;
  transition: max-height 0.25s ease-in;
}

.firstLoad {
  display: none;
}

.place-item {
  display: flex;
  gap: 20px;
  text-align: center;
  font-size: 20px;
  margin: 10px;
  padding: 5px;
  background-color: #c9ada7;
  cursor: pointer;
  border-radius: 3px;
}
.place-item p {
  text-align: left;
  margin: 2px;
}
.place-item .remove-place-btn {
  font-weight: 700;
  font-size: 25px;
  border-radius: 50%;
  border: none;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #22223b;
  color: #c9ada7;
  cursor: pointer;
}
.place-item .remove-place-btn span {
  margin-bottom: 4px;
}

.fall {
  animation: fallAway forwards 1s ease-out 1;
}

@keyframes fallAway {
  0% {
    transform: rotateZ(0deg);
    top: 0;
    opacity: 1;
  }
  25% {
    transform: rotateZ(-15deg);
  }
  100% {
    top: 300px;
    transform: rotateZ(-5deg);
    opacity: 0;
  }
}
.currentlocation-component-wrapper {
  background-color: #22223b;
  width: 70%;
  margin: 20px;
  margin-top: 200px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.pulse-animation {
  animation: pulse 1s;
  animation-iteration-count: 1;
}

.removal-pulse {
  animation: red-pulse 1s;
  animation-iteration-count: 1;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(249, 242, 242);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
@keyframes red-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(220, 149, 149);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #e94057;
  }
}
.current-location {
  color: #f2e9e4;
  font-size: 16px;
}

.add-location-btn {
  font-weight: 700;
  font-size: 25px;
  border-radius: 50%;
  border: none;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c9ada7;
  cursor: pointer;
}
.add-location-btn span {
  margin-bottom: 4px;
}
.add-location-btn span {
  margin-top: 5px;
  margin-left: 1px;
}

.map-container {
  width: 500px;
  height: 400px;
  border: 10px solid #22223b;
  border-radius: 32px;
  margin-bottom: 20px;
}

.right-column {
  max-height: fit-content;
  background-color: #9a8c98;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
}

.logout-btn {
  background-color: #4a4e69;
  color: #f2e9e4;
  font-weight: 700;
  border: none;
  width: 150px;
  height: 50px;
  border-radius: 16px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin: 40px 30px;
}

@media (max-width: 1050px) {
  .homepage-wrapper {
    display: flex;
    flex-direction: column;
  }
  .right-column {
    margin-top: 0;
  }
}
@media (max-width: 576px) {
  .map-container {
    width: 90%;
    margin: auto;
  }
  .locations-dropdwn-label {
    margin-top: 20px;
    font-size: 20px;
    width: 250px;
    padding: 3.5%;
  }
  .GN-title {
    font-size: 25px;
  }
  .logout-btn {
    width: 100px;
    margin-right: 20px;
    margin-top: 30px;
  }
  .add-location-btn {
    height: 40px;
    width: 40px;
  }
  .add-location-btn span {
    color: #22223b;
  }
  .currentlocation-component-wrapper {
    width: 95%;
    height: 60px;
  }
  .current-location {
    letter-spacing: 0.01px;
    text-align: center;
  }
  .place-item .remove-place-btn {
    padding: 4%;
    align-self: center;
  }
}

