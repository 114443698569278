@use "globalstyles";
@use "landingpage";
@mixin dropdown {
  list-style-type: none;
  background-color: map-get(globalstyles.$palette, darkest);
  width: 350px;
  text-align: center;
  padding: 0;
  margin-top: 0;
  border-radius: 6px 6px 12px 12px;
  height: 100%;
  transition: height 1s;
}

@mixin btn-shape {
  font-weight: map-get(globalstyles.$font-weights, bold);
  font-size: map-get(globalstyles.$font-sizes, medium);
  border-radius: 50%;
  border: none;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-bottom: 4px;
  }
}
.homepage-wrapper {
  height: 100vh;
  background-color: map-get(globalstyles.$palette, light-prpl);
  display: grid;
  grid-template-columns: 50% 50%;
}

.left-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: map-get(globalstyles.$palette, light-prpl);
}

.GN-title {
  color: map-get(globalstyles.$palette, lightest);
  font-weight: map-get(globalstyles.$font-weights, regular);
  font-size: map-get(globalstyles.$font-sizes, large);
  margin: 0;
  padding: 40px;
  position: absolute;
  left: -5px;
}

// SAVED LOCATIONS

.savedplaces-component-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.locations-dropdwn-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: map-get(globalstyles.$palette, darkest);
  color: map-get(globalstyles.$palette, lightest);
  font-weight: map-get(globalstyles.$font-weights, regular);
  font-size: map-get(globalstyles.$font-sizes, medium);
  width: 400px;
  padding: 5%;
  border-radius: 32px;
  cursor: pointer;
}

.savedplaces-list-closed {
  @include dropdown();
  max-height: 0;
  margin: 0;
  transition: max-height 0.1s ease-out;
  overflow: hidden;
}

.savedplaces-list {
  @include dropdown();
  max-height: 1000px;
  transition: max-height 0.25s ease-in;
}

.firstLoad {
  display: none;
}

.place-item {
  display: flex;
  gap: 20px;
  text-align: center;
  font-size: map-get(globalstyles.$font-sizes, small);
  margin: 10px;
  padding: 5px;
  background-color: map-get(globalstyles.$palette, light-pink);
  cursor: pointer;
  border-radius: 3px;
  p {
    text-align: left;
    margin: 2px;
  }

  .remove-place-btn {
    @include btn-shape;
    background-color: map-get(globalstyles.$palette, darkest);
    color: map-get(globalstyles.$palette, light-pink);
    cursor: pointer;
  }
}

.fall {
  animation: fallAway forwards 1s ease-out 1;
}
@keyframes fallAway {
  0% {
    transform: rotateZ(0deg);
    top: 0;
    opacity: 1;
  }
  25% {
    transform: rotateZ(-15deg);
  }
  100% {
    top: 300px;
    transform: rotateZ(-5deg);
    opacity: 0;
  }
}
//CURRENT LOCATION

.currentlocation-component-wrapper {
  background-color: map-get(globalstyles.$palette, darkest);
  width: 70%;
  margin: 20px;
  margin-top: 200px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.pulse-animation {
  animation: pulse 1s;
  animation-iteration-count: 1;
}
.removal-pulse {
  animation: red-pulse 1s;
  animation-iteration-count: 1;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(249, 242, 242);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
@keyframes red-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(220, 149, 149);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #e94057;
  }
}

.current-location {
  color: map-get(globalstyles.$palette, lightest);
  font-size: map-get(globalstyles.$font-sizes, x-small);
}

.add-location-btn {
  @include btn-shape;
  background-color: map-get(globalstyles.$palette, light-pink);
  cursor: pointer;
  span {
    margin-top: 5px;
    margin-left: 1px;
  }
}

.map-container {
  width: 500px;
  height: 400px;
  border: 10px solid map-get(globalstyles.$palette, darkest);
  border-radius: 32px;
  margin-bottom: 20px;
}

.right-column {
  max-height: fit-content;
  background-color: map-get(globalstyles.$palette, light-prpl);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
}

.logout-btn {
  @include landingpage.gn-login-btns;
  position: absolute;
  right: 0;
  top: 0;
  margin: 40px 30px;
}

@media (max-width: 1050px) {
  .homepage-wrapper {
    display: flex;
    flex-direction: column;
  }
  .right-column {
    margin-top: 0;
  }
}
@media (max-width: 576px) {
  .map-container {
    width: 90%;
    margin: auto;
  }

  .locations-dropdwn-label {
    margin-top: 20px;
    font-size: map-get(globalstyles.$font-sizes, small);
    width: 250px;
    padding: 3.5%;
  }

  .GN-title {
    font-size: map-get(globalstyles.$font-sizes, medium);
  }
  .logout-btn {
    width: 100px;
    margin-right: 20px;
    margin-top: 30px;
  }

  .add-location-btn {
    height: 40px;
    width: 40px;
    span {
      color: map-get(globalstyles.$palette, darkest);
    }
  }

  .currentlocation-component-wrapper {
    width: 95%;
    height: 60px;
  }
  .current-location {
    letter-spacing: 0.01px;
    text-align: center;
  }

  .place-item {
    .remove-place-btn {
      padding: 4%;
      align-self: center;
    }
  }
}
