.resume-page-wrapper {
  background-color: black;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 20px;
}
.resume-header {
  margin: 0 0 20px 0px;
  color: #d4b36d;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  margin-top: -80px;
}

.resume-wrapper {
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

.section-header {
  color: #d4b36d;
  font-weight: 600;
}

.body-paragraph {
  color: #d4b36d;
  font-size: 16px;
  text-align: justify;
  line-height: 25px;
}

.tech-header {
  color: #d4b36d;
  font-size: 16px;
  font-weight: 600;
}

.resume-page-wrapper span {
  color: #d4b36d;
  display: inline;
}

.experience-name {
  color: #d4b36d;
  font-size: 16px;
  font-weight: 400;
}

.title-dates-split {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -15px;
}

.date {
  color: #d4b36d;
  font-size: 16px;
  font-weight: 400;
  align-self: center;
  margin-top: 0;
}

.position {
  color: #d4b36d;
  margin-top: 0;
  font-weight: 400;
  margin-left: 5px;
  font-size: 14px;
}

.project {
  color: #d4b36d;
  margin-top: 0;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

.list {
  color: #d4b36d;
  margin-top: 0;
  list-style-type: circle;
}

.list li {
  font-size: 14px;
  text-decoration: none;
  line-height: 20px;
  padding: 5px;
}

.education-paragraph {
  color: #d4b36d;
  font-size: 14px;
  margin-top: -5px;
}

.download {
  padding: 0;
  margin: 0;
  background-color: black;
  border: none;
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 150px;
  top: 70px;
  border-radius: 50%;
}

.download img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: -16px;
}

.download:hover {
  box-shadow: 0 12px 16px 0 #d4b36d, 0 17px 50px 0 #d4b36d;
}

@media (max-width: 1100px) {
  .resume-page-wrapper {
    height: 100%;
  }
  .download img {
    margin-top: -0px;
  }
}
@media (max-width: 850px) {
  .download {
    top: 85px;
    right: 15%;
  }
  .download img {
    height: 30px;
    width: 30px;
  }
}
@media (max-width: 700px) {
  .download {
    right: 20%;
  }

  .body-paragraph {
    font-size: 15px;
  }
  .section-header {
    font-size: large;
  }

  .experience-name {
    font-size: 15px;
    font-weight: 600;
  }
  .position {
    font-weight: 600;
  }
  .date {
    font-size: 15px;
  }

  .list li {
    font-size: 14px;
  }
  .download img {
    margin-top: -16px;
  }
}
@media (max-width: 600px) {
  .download {
    /* top: 70px; */
  }
}
@media (max-width: 500px) {
  .title-dates-split {
    gap: 10px;
  }
  .date {
    text-align: justify;
    justify-self: flex-end;
    /* text-align: right; */
  }
  .download {
    top: 65px;
    right: 20%;
  }
}
@media (max-width: 400px) {
  .download {
    top: 57px;
    right: 25%;
    border-radius: 0px;
    display: inline-block;
    overflow: visible;
  }

  .download img {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    display: inline-block;
    overflow: visible;
  }
}
