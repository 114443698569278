@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,400;1,500;1,600&family=Roboto:wght@100;300&display=swap");

html {
  height: 100%;
  background-color: black;
}

html,
body {
  scroll-behavior: smooth !important;
}
.background-covering {
  background-color: black;
  height: 100%;
}

.App {
  text-align: center;

  /* background-image: url(assets/background.jpg);
  background-size: cover; */
  background-color: #000;
}
