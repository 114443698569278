@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.bars-wrapper {
  width: 100%;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #d5b060;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  top: 10px;
  -webkit-transform: rotate(-45deg) translate(-7.7px, 7px);
  /* transform: rotate(-45deg) translate(-8.7px, 7px); */
  transform: rotate(-45deg) translate(-7.7px, 7px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  margin-top: 0.5px;
  -webkit-transform: rotate(45deg) translate(-7px, -9px);
  transform: rotate(45deg) translate(-7px, -9px);
}

.square-btn {
  z-index: 1000;
  margin: 8px;
  position: fixed;
  right: 30px;
  top: 40px;
  border: none;
  padding-top: 1px;
  box-shadow: 2px 2px 40px #976508;
  background: radial-gradient(#976508, black);
}

.link {
  z-index: 1;
  color: #d5b060;
  font-size: 20px;
  cursor: pointer;
  font-size: 20px;
  padding: 0 22px 0 0;
  text-decoration: underline;
}

/* SIDEBAR AND SOCIALS */

.closed-sidebar {
  display: none;
}

.socials-wrapper {
  color: #d5b060;
  z-index: 2000;
  transform: translateX(200px);
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.socials-wrapper-ul {
  display: flex;
  justify-content: end;
  /* margin-top: -35px; */
}

.open-homepage-version {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  background-color: black;
  font-family: "Montserrat", sans-serif;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}
.open-regular-version {
  display: flex;
  background-color: black;
  font-family: "Montserrat", sans-serif;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}
.lrg-window-slideout {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}
.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

.regular-window-slideout {
  display: flex;
  flex-direction: row;
  animation: nav-slide-out 0.5s forwards;
  -webkit-animation: nav-slide-out 0.5s forwards;
  width: 0px;
}

@keyframes nav-slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(150%);
  }
}

@-webkit-keyframes nav-slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(150%);
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(150%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(150%);
  }
}

.nav,
.regular-window-slideout,
.open {
  overflow: hidden;
}

@media (max-width: 1300px) {
  .socials-wrapper-ul {
  }
  .nav {
  }
  .open-regular-version,
  .open-homepage-version {
    min-width: 50vw;
    padding: 50px;
    gap: 40px;
    top: 00px;
    min-height: 100vh;
    right: 0;
    flex-direction: column;
    align-items: flex-start;
    z-index: 400;
    position: fixed;
    box-shadow: -9px 0px 100px 40px #151311;
  }
  .link {
    font-weight: 500;
  }
}

@media (max-width: 1100px) {
  .square-btn {
    margin-top: 26px;
  }
}
@media (max-width: 850px) {
  .link {
    font-size: 16px;
  }
  .square-btn {
    right: 32px;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 25px;
    height: 3px;
  }
  .square-btn {
    margin-top: 42px;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-8.7px, 7px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-4px, -6.5px);
  }
  .socials-wrapper-ul {
    /* margin-top: 28px; */
    right: 0;
  }
}

@media (max-width: 700px) {
  .square-btn {
    margin-top: 25px;
  }
}

@media (max-width: 500px) {
  .square-btn {
    margin-top: 5px;
  }
  .blog-wrapper .square-btn {
    margin-top: 0px;
    margin-right: 0;
  }
  .link {
    font-size: 14px;
  }

  .square-btn {
    right: 10px;
  }
}
@media (max-width: 400px) {
  .blog-wrapper .square-btn {
    margin-top: 0px;
    right: 0px;
  }

  .square-btn {
    margin-top: 0;
  }
}
