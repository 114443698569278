@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');

.cluster{
  float: right;
  margin-right:0;
  margin-left:0;
} 
.contact-input-field{

  font-size: 12px;
  border: 2px solid #D5AF60;
  color: white;
  font-family: 'Montserrat', sans-serif;
  border-radius: 16px;
  background-color: #1A1404;
}



.form-line-wrapper{
  width:  70%;
  display: flex;
  align-items: flex-start;
  gap: 50px;
  flex-wrap: wrap;

}


.contact-page-wrapper{
  background-color: black;
  height: 110vh;
  font-family: 'Montserrat', sans-serif;


}

.form-label{
  background-color: #826617;
  font-size: 12px;
  color: white;
  padding: 1.8%;
  border-radius: 16px;
}

.err{
  font-family: 'Montserrat', sans-serif;
  color: white;
  background-color: #E94057;
  padding: 10px;
  margin: 0;
  
  border-radius: 16px;
  font-size: small;


}

.err-span{
  margin-top: 5px;

}


textarea{
  width: 50%;
  vertical-align: middle;
  box-sizing: border-box;
  padding: 10px;
}


.form-success{
  color: #D5AF60;
  text-align: center;
  margin-top: 0;
  padding-top:0;
  display: flex;
  font-size: 24px;
  width: 50%;
}

.success-msg{
  margin-top: 0;
  padding-top:0;
  font-weight: 400;
}

.contact-form-wrapper {

 
  width: 95%;
  margin: auto;
  margin-bottom: 2%;
  border-width: 10px;
  border-style: dashed solid;
  border-image-slice: 30;
  border-image: 
    linear-gradient(
      to bottom, 
      black, 
      /* #623F74, */
      #D5AF60,
      black,
      rgba(0, 0, 0, 0)
    ) 1 50%;
  
}
.nav-open-contact{

  width: 95%;
  margin: auto;
  margin-bottom: 2%;
  border-width: 10px;
  border-style: dashed solid;
  border-image-slice: 30;
  border-image: 
    linear-gradient(
      to bottom, 
      black, 
      /* #623F74, */
      #D5AF60,
      black,
      rgba(0, 0, 0, 0)
    ) 1 50%;
}

.nav-open-contact img{
  display: none;
}

.name-input-div{
  
}

.name-input{
  height: 25px;
  padding: 1.2%;
  border-radius: 16px;


}

.email-input-div{


}

.email-input{

  height: 25px;
  padding: 1.2%;
}


.message-input-div{

}

.textarea-label{

  margin-top: 5px;
  height: 50%;
 
}

.message_field{
  height: 120px;
  width: 500px;
  background-color: #1a1404;
  margin-left: -20px;
}


.contact-intro{
  font-size: 24px;
  padding: 0 5% 5% 5%;
  font-weight: 400;
  color: #D5AF60;
  margin-bottom: 0;
}


.form-wrapper{
  width: 90%;
  margin: auto;

}

.contact-form-wrapper input:focus { 
  outline: none !important;
  border-color: #997079;
  box-shadow: 0 0 10px #77525a;
}
.contact-form-wrapper textarea:focus { 
  outline: none !important;
  border-color: #997079;
  box-shadow: 0 0 10px #77525a;
}



.message-input-div .err-span{

}

.form-wrapper div{
  margin-bottom: 5%;
}


.form-input-btn{
  font-size: 12px;
  border-radius: 15px;
  color:white;
  background-color: #826617;
  padding: 50%;
  border: none;



}


.form-input-btn:hover{
  cursor:pointer;
 
  background-color: #52373D;
  color: white;

  /* color: white;
  border-bottom: 4px solid darken($color, 10%); */
}
.btn-div{


  margin-top: 10px;
}



.form-input-btn:active{
  background-color: linear-gradient(to left, #F27121, #E94057, #8A2387);
}



@media(max-width: 1405px){
  .cluster{
    width: 340px;
  }
 
}

@media(max-width:1330px){
 .cluster{
  width: 300px;
 }
}

@media(max-width:1200px){
  .btn-div{
    height: 30px;
    
  }

  .cluster{
    width: 280px;
   }
}
@media(max-width:1100px){
  .cluster{
    width: 250px;
  }

  .form-line-wrapper{
    flex-wrap: nowrap;
  }
}
@media(max-width:1000px){
  .cluster{
    width: 200px;
  }
  .form-wrapper label{
    font-size: 12px;
  }
  .contact-intro{
    font-size: 25px;
  }

  .btn-div{
    position: absolute;
    margin-top: 150px;
    
  }

  .form-input-btn{
    text-align: center;
    height: 40px;
    padding:10px;
  }

  .form-success{
   width: 80px;
   margin: auto;
  }
}



@media(max-width:900px) {
  .contact-intro{
    font-size: 20px;

  }

  .nav-open-contact{
    display: none;
  }

}

@media(max-width:800px){
  .cluster{
    width: 150px;
  }

  .form-label{
    padding: 10px;
  }
  .success-msg{
    font-size: 20px;
    margin-left: 10px;
  }
}

@media(max-width:750px){
  .form-input-btn{

  }


}

@media(max-width: 700px){

  .contact-intro{
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 25px;
  }

  .btn-div{
    font-size: 15px;
  }

  .cluster{
    width: 130px;
  }

  .err{
    font-size: 11px;
  }
}

@media(max-width: 600px){
  .success-msg{
    font-size: 18px;
  }
  .contact-form-wrapper{
    border-width: 8px;
  }
  .cluster{
    display: none;
  }
 .form-line-wrapper{
   width: 90%;
 }

 .err{
   font-size: 10px;
 }
}

@media(max-width: 500px){

  
  .contact-intro{
    font-size: 15px;
  }

  .form-wrapper label{
    font-size: 11px;
    border-radius: 15px;
  }

  .err{

  }

  .form-input-btn{
    font-size: 12px;
    left: 0;
    
  }
  .form-wrapper div{
    margin-bottom: 35px;
  }
}

@media (max-width: 450px) {
  .success-msg{
    font-size: 16px;
  }
  .contact-intro{
    font-size: 16px;

  }

  .contact-input-field{
    font-size: 10px;
    width: 145px;
  }
  .form-line-wrapper{
    flex-wrap: wrap;
    grid-gap: 20px;
  }

  .err-span{
    margin-top: 0;
    margin-bottom: 10px;
  }

  .message_field{
    width: 200px;
    margin-left: 0px;
    margin-right: 0;
  }

  .message-input-div span{
    position: absolute;
    right: 10px;
    margin-top: 5px;
  }
}

@media(max-width: 400px){

  .form-line-wrapper{
    width: 100%;
    grid-gap: 20px;
  }
  .err{
    padding: 7px;
  }
  .message-input-div span{
    position: relative;
  }

  .message_field{
    width: 140px;
  }

  .message_field:focus{
    width: 200px;
    transition: ease-in .5s;

  }



  .form-wrapper label{
    font-size: 9px;
  }
}
