.about-flexbox {
  display: flex;
  margin: auto;
}

.about-section {
  margin: 5%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headshot {
  max-height: 600px;
  margin-top: -40px;
}

.headshot-wrapper {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

.about-section {
  text-align: justify;
  font-size: 18px;
  margin-top: 0;

  letter-spacing: 0.1px;
  font-weight: 250;
  line-height: 1.5em;
  color: #edc671;
}

.header {
  color: #e6c275;
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 60px;
}
.header span {
  font-weight: 600;
  font-size: 30px;
}

@media (max-width: 1000px) {
  .about-flexbox {
    margin-top: 50px;
  }
  .header {
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 40px;
  }

  .about-content {
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0;
  }

  .headshot {
    margin-top: 0px;
  }
}

@media (max-width: 820px) {
  .about-flexbox {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .header {
    font-size: 20px;
    margin-bottom: 5px;
    line-height: normal;
    margin-top: auto;
    max-width: 340px;
    text-align: left;
  }

  .about-content {
    font-size: 16px;
    text-align: left;
  }
}

@media (max-width: 501px) {
  .header span {
    font-size: 25px;
  }

  .about-content {
    margin-right: 0;
    padding-right: 0;
  }

  .page-wrapper {
    padding-bottom: 20px;
  }
}

@media (max-width: 400px) {
  .headshot {
    width: 80%;
  }
}

@media (min-height: 900px) {
  .page-wrapper {
    padding-bottom: 50%;
  }
}
@media (min-height: 1000px) {
  .page-wrapper {
    padding-bottom: 40%;
  }
}
@media (min-height: 1100px) {
  .page-wrapper {
    padding-bottom: 60%;
  }
}
