.textField__label{
  color: white !important;
}



.searchbar input[type=text] {
  border: 2px;
  border-radius: 4px;
  padding: 30px 10px;
  color: #FEFAE0;
  font-weight: bold;

}


.searchbar{
  background-color: #DDA15E;

}

@media(max-width: 700px){
  .searchbar input[type=text] {
   font-size: 14px;
  
  }
  

  
}

  

  
