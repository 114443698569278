@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100&family=Roboto:wght@100;300&display=swap");

.fade-in {
  animation: fade-in 1s 1;
  -webkit-animation: fade-in 1s 1;
  animation-fill-mode: forwards;
  background-color: black;

  -webkit-animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  90% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
}

.sidebar-and-socials-wrapper {
  float: right;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 5px;
  width: 610px;
}

@media (max-width: 1050px) {
  .apps-section {
    grid-template-columns: 100%;
  }
}

.move {
  animation: my0rbit 7s linear infinite;
  -webkit-animation: myOrbit 7s linear infinite; /* Chrome, Safari 5 */
}

.move-2 {
  animation-delay: 2s;
  animation: myOorbit 7s linear infinite;
  -webkit-animation: myOrbit 7s reverse infinite; /* Chrome, Safari 5 */
}

@keyframes myOrbit {
  from {
    transform: rotate(0deg) translateX(25%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(25%) rotate(-360deg);
  }
}

.socials-wrapper img {
  height: 25px;
}

.socials-wrapper ul {
  padding: 0;
  margin-bottom: 0;
}

.socials-wrapper ul > li {
  display: inline-block;
  list-style: none;
  padding: 2px 20px 0;
}

.socials-wrapper img {
  margin-top: 15px;
}

.socials-li:hover {
  transform: scale(1.15);
  transition: 0.2s ease-in;
}

.homepage-header {
  display: flex;
  color: #edc671;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  flex-direction: column;
  position: absolute;
  left: 49%;
  transform: translateX(-50%);
  top: 10%;
  mix-blend-mode: plus-lighter; /* Choose an appropriate blend mode  */
}

.homepage-header h1,
h2 {
  font-weight: 400;
}

.homepage-header h1 {
  margin: 7px;
}
.landing-section {
  background-image: url("../../assets/nrhero.png");
  background-position: bottom center, bottom left;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 100px;
}

.landing-page-menu {
  color: #edc671;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  width: auto;
}

.landingpage-ul {
  list-style-type: none;
  display: flex;
  padding-left: 0px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
}

.main-lp-li {
  display: inline;
  margin: auto 20px;
}

.main-lp-li-btn {
  font-size: 20px;
  border: 2px solid black;
  background-color: black;
  color: #edc671;
  cursor: pointer;
  padding: 10px;
  width: 100px;
  transition: border-color 0.3s ease; /* Transition property for border-color */
}
.main-lp-li-btn:hover {
  border: 2px solid #edc671;
  box-shadow: 0px 0px 5px rgba(237, 198, 113, 0.5);
}

.transition-gradient {
  position: absolute;
  top: 0;
  z-index: -1;
}
.transition-gradient img {
  height: 10%;
}
.typing-wrapper {
  display: flex;
  justify-content: center;
}
.projects-section-header {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  margin-top: 100px;
  color: #9f8d65;
  padding: 5%;
}

.projects-section-header h1 {
  margin: 0 auto;
  font-weight: 500;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.15em solid #d0b16a;
  animation: typing 3.5s steps(15, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.blacked-out {
  font-color: black;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #d0b16a;
  }
}
/* Apply the typing animation to the text */

/* PROJECT SECTION */
.project-grid {
  display: flex;
  width: 80%;
  margin: 100px auto;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 2;
}
.projectcard-box {
  flex-basis: calc(50% - 45px);
  background-color: rgba(34, 110, 101, 0.7);
  box-sizing: border-box;
  color: #d0b16a;
  font-family: "Montserrat", sans-serif;
  z-index: 2;
}
.projectcard-content {
  margin: 10px;
}
.project-title {
  font-weight: 600;
}

.projectcard-btns-section {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.project-card-btn {
  width: 100px;
  background: none;
  border: 1px solid #d0b16a;
  color: #dbb969;
  padding: 4%;
  cursor: pointer;
  transition: all 0.3s ease, transform 0.3s ease;
  max-height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-card-btn a {
  color: inherit;
  text-decoration: none;
}

.project-card-btn p {
  margin: 0px;
  padding: 0px;
}

.project-card-btn:hover {
  border-color: white;
  color: white;
  transform: scale(1.1);
}

.thumbnail-img {
  max-width: 100%;
  cursor: pointer;
  transition: transform 0.5s ease;
  transform: scale(1.01);
}

.thumbnail-wrapper {
  /* Add transition for smooth animation */
  overflow: hidden;
  max-height: 200px; /* Set the initial max-height */
  transition: max-height 0.5s ease; /* Add transition for smooth reveal */
}

.thumbnail-wrapper-hovered {
  overflow: hidden;
  transition: max-height 1s ease;
  max-height: 1000px;
}

@keyframes revealBounce {
  0% {
    max-height: 50%;
  }

  80% {
    max-height: 110%;
  }

  100% {
    max-height: auto;
    transition: max-height 1s ease;
  }
}

/* CTA BUTTON  */
.bottom-cta-wrapper {
  display: flex;
  justify-content: center;
  margin-top: -30px;
  padding-bottom: 60px;
  gap: 20px;
}
.bottom-cta {
  font-size: 16px;
  border: 2px solid hsl(41, 78%, 69%, 0.8);
  background-color: black;
  color: hsl(41, 78%, 69%, 0.8);
  cursor: pointer;
  padding: 10px;
  width: 100px;
  transition: border-color 0.3s ease;
  border-radius: 8px;
}

.bottom-cta:hover {
  border-color: rgb(94, 157, 149);
  color: white;
}
/* MEDIA QUERIUES */

@media (max-width: 1100px) {
  .socials-wrapper img {
    width: 25px;
    margin-top: 20px;
  }
  .socials-wrapper {
    right: 125px;
  }
}

@media (max-width: 1050px) {
  .apps-section {
    padding-bottom: 100px;
  }
}

@media (max-width: 900px) {
  .socials-wrapper {
    right: 100px;
  }
  .project-grid {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .socials-wrapper {
    top: 38px;
    right: 100px;
  }
  .socials-wrapper img {
    height: 20px;
    width: 20px;
  }
  .project-grid {
    width: 95%;
  }

  .homepage-header {
  }
}
@media (max-width: 700px) {
  .homepage-header {
    left: 50%;
  }
  .homepage-header h1 {
    font-size: 1.7rem;
  }
  .homepage-header h2 {
    font-size: 1.2rem;
  }
  .main-lp-li-btn {
    font-size: 18px;
  }

  .main-lp-li {
    margin: auto 10px;
  }

  .socials-mobile-view {
    top: 64px;
    position: absolute;
    left: 20.5px;
  }

  .socials-wrapper {
    top: 88.4px;
    left: 22px;
    border-left: 3.5px solid #d5b060;
    height: 220px;
    width: 20px;

    margin-top: -40px;
    animation: none;
    transform: none;
  }

  .socials-wrapper ul {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 50px;
  }
  .socials-wrapper ul li {
    margin-top: 5px;
  }
  .socials-wrapper img {
    height: 20px;
    width: 20px;
  }

  .socials-li {
    width: 10px;
    padding-bottom: 0;
  }
  .project-grid {
    flex-direction: column;
    width: 75%;
  }
}

@media (max-width: 600px) {
  .main-lp-li {
    margin: auto 0px;
  }

  .bottom-cta {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .socials-wrapper {
    top: 75px;
    left: 15px;
  }

  .socials-wrapper img {
    height: 15px;
    width: 15px;
  }
  .main-lp-li-btn {
    font-size: 16px;
    background-color: rgba(87, 125, 146, 0.3);
    border-radius: 6px;
  }

  .landingpage-ul {
    display: flex;
    flex-wrap: wrap;
  }
  .main-lp-li {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 6px;
  }
  .landing-page-menu {
    top: 43%;
    width: 100%;
  }
}
@media (max-width: 450px) {
  .landing-page-menu {
    /* top: 55%; */

    width: 100%;
  }
}
@media (max-width: 400px) {
  .socials-wrapper {
    top: 64.5px;
  }
  .socials-wrapper ul {
    margin-top: 40px;
  }
}

@media (min-width: 1024px) and (min-height: 600px) and (max-height: 700px) {
  .homepage-header {
    top: 8%;
  }
}
@media (min-width: 1280px) and (min-height: 800px) {
  .homepage-header {
    top: 8%;
  }
  .landing-page-menu {
    /* top: 70vh; */
  }
}
/* IPAD MINI */

@media (min-width: 768px) and (min-height: 1024px) {
  .homepage-header {
    top: 25%;
  }
  .landing-page-menu {
    top: 46%;
  }
}

/* IPAD AIR */
@media (min-width: 820px) and (max-width: 830px) and (min-height: 1180px) {
  .homepage-header {
    top: 20%;
  }
  .landing-page-menu {
    /* top: 80vh; */
  }
}
@media (min-width: 1180px) and (max-width: 1181px) and (min-height: 820px) {
  .homepage-header {
    top: 17.5%;
  }
  .landing-page-menu {
    /* top: 71vh; */
  }
}

@media (min-width: 1024px) and (max-width: 1034px) and (min-height: 600px) and (max-height: 601px) {
  .landing-section {
    background-position: top center;
  }
}

@media (min-width: 912px) and (min-height: 1368px) {
  .homepage-header {
    top: 25%;
  }
}

/* LANDSCAPE MODE */

@media (max-width: 916px) and (max-height: 420px) {
  .homepage-header h2 {
    white-space: nowrap;
  }
  .landing-section {
    padding-top: 50px;
  }

  .landing-page-menu {
    top: 42%;
    background-color: black;
    border-radius: 32px;
  }
  .landingpage-ul {
    width: 80%;
    margin: auto;
  }

  .main-lp-li-btn {
    font-size: 18px;
  }
}

@media (max-width: 720px) and (max-height: 540px) {
  .landing-section {
    background-position: top center;
  }

  .homepage-header {
    margin-top: -26px;
  }
  .homepage-header h2 {
    white-space: nowrap;
  }
  .landing-page-menu {
    top: 59%;
  }
}

/* MAKE A SMALLER ONE FOR IPHONE SE */
@media (max-width: 667px) and (max-height: 375px) {
  .landing-page-menu {
    top: 50%;
    background-color: black;
  }

  .main-lp-li-btn {
    padding-left: 0px;
    padding-right: 0px;
  }

  .landingpage-ul {
    display: flex;
    justify-content: center;
  }
}
