@use "globalstyles";
:root {
    --card-width: 250px;
    --card-border-radius: 16px;
    --row-increment: 5px;
    --card-small: 15;
    --card-medium: 22;
    --card-large: 25; 
 }

 @mixin bulletin-component-wrapper-style{
   height: 570px;
   width: 500px; 
   background-color: map-get(globalstyles.$palette, darkest);
   margin-top: 100px;
   border-radius: 12px;
   padding: 5px;
   overflow: scroll;
   overflow-y:scroll;
   overflow: -moz-scrollbars-vertical; 
 }

 .bulletin-component-wrapper{ 
   @include bulletin-component-wrapper-style;
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--card-width)); //handles cards wrapping to next row 
    grid-template-rows: var(--row-increment);//grid auto rows handles height of card
   //  grid-auto-rows: minmax(min-content, max-content);
    justify-content: flex-start;
 }

 .card{
   height: auto;
    padding: 0px;
    margin: 5px;
    border-radius: 12px;
    background-color: map-get(globalstyles.$palette, light-pink);
 }

 .card-small{
    grid-row-end: span var(--card-small );
 }
 .card-medium{
    grid-row-end: span var(--card-medium );
 }
 .card-large{
    grid-row-end: span var(--card-large );
 }

 .bulletin-component-wrapper-no-data{
   @include bulletin-component-wrapper-style();
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .default-bulletin{
   color: map-get(globalstyles.$palette, light-pink);
   text-align: center;
   vertical-align: middle;
 }

 .gn-box-content{
   list-style-type: none;
   display: flex;
   flex-direction: column;
   transition: all .2s ease-in-out; 
   p{
      padding-left: 10px;
      padding-right: 10px;
   }
 }

 .gn-box-content:hover { 
   transform: scale(1.05); 
   }

 .gn-article-title{
   color: map-get(globalstyles.$palette, darkest);
   font-weight: map-get(globalstyles.$font-weights, semi-bold);
   margin-bottom: 10px;
 }

 .gn-publisher-div{
   display: flex;
   align-items: center;
   background-color: map-get(globalstyles.$palette, light-gray);
   height: 100%;
   border-radius: 12px;


 }
 .gn-publisher{
   font-size: map-get(globalstyles.$font-sizes, xx-small);
   color: map-get(globalstyles.$palette, light-pink);
   
 }

 @media(max-width: 1050px){
   .bulletin-component-wrapper{
      margin-top: 50px;
   }
   .bulletin-component-wrapper-no-data{
      margin-top: 50px;
   }
   
}

@media(max-width: 576px){
   .bulletin-component-wrapper{
      width: 300px;
      margin-bottom: 20px;
   }

   .bulletin-component-wrapper-no-data{
      width: 300px;
      margin-bottom: 20px;
   }

   :root {
      --card-width: 150px;
   }

}