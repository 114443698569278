@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;800&display=swap");
.blog-page-wrapper {
  background-color: black;
  height: 100%;
}

.blog-menu-flexbox {
  padding-top: 120px;
  display: flex;
  margin: auto;
}

.blog-page-description-wrapper {
  flex: 1;
  flex-shrink: 0;
}
.blog-menu-header {
  max-width: 150px;
  font-family: "Montserrat", sans-serif;
  color: #edc671;
  margin-top: 10%;
  margin-right: 10%;
  margin-left: 10%;
  font-size: 50px;
}
.blog-menu-header span {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}
.blog_div {
  box-sizing: content-box;
}

.post {
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 8%;
}

.blog-nav {
  flex: 1;
}

.blog-nav ul {
  display: flex;
  flex-direction: column;
  gap: 10%;
  width: 80%;
  margin: auto;
  margin-right: 12%;
  padding: 0px;
  margin-top: -70px;
}

.blog-tab {
  color: #edc671;
  list-style-type: none;
  font-size: 26px;
  font-weight: lighter;
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 20px;
  transition: transform 0.2s ease-in-out;
}

.blog-tab:hover {
  transform: scale(1.05);
}

/* .blog-tab::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #d4b36d;
  transition: width 0.2s ease-in-out;
}

.blog-tab:hover::after {
  width: 100%;
} */

.blog-tab h3 {
  font-size: 24px;
  font-weight: normal;
  margin-left: 20px;
}

.tech-tab {
  background-color: #2c2006;
}
.writing-tab {
  background-color: #401233;
}
.arsenal-tab {
  background-color: #263c49;
}
.music-tab {
  background-color: #2f1565;
}

.hide-coming-soon-msg {
  opacity: 0;
  margin-bottom: 10px;
  transition: opacity 0.3s ease-in-out;
}

.show-coming-soon-msg {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  color: #edc671;
  position: absolute;
  top: 15%;
  right: 100px;
}

.default-label-class {
  transition: all 0.5s ease;
  text-transform: capitalize;
  transform: translateX(0%);
}
.slide-out,
.slide-in {
  transition: transform 0.5s ease-in-out; /* Apply transition to transform property */
}

.slide-out {
  transform: translateX(-100%);
}

.slide-in {
  transform: translateX(100%);
}

@media (max-width: 1000px) {
  .wallpaper-extended {
    background-color: black;
  }
}

@media (max-width: 900px) {
  .blog_div {
    margin-top: 12%;
  }

  .blog_div button {
    font-size: 100%;
  }

  .navOpen {
    display: none;
  }
  .blog-tab h3 {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .post {
    margin-top: 400px;
  }

  .blog-nav ul {
    margin-top: 0px;
  }

  .blog-menu-flexbox {
    padding-top: 0px;
    flex-direction: column;
  }
  .blog-menu-header {
    font-size: 40px;
  }
  .blog-tab h3 {
    font-size: 22px;
  }
}

@media (max-width: 600px) {
  .blog-tab h3 {
    font-size: 22px;
  }
  .blog-nav {
    width: auto;
  }
}
@media (max-width: 500px) {
  .post {
    margin-top: 300px;
  }
}
@media (max-width: 450px) {
  .blog-nav ul {
    padding-left: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 5px;
    margin-top: 40px;
  }
  .blog_div {
    width: 90%;
    margin: auto;
  }
}

@media (max-width: 400px) {
  .blog-tab h3 {
    font-size: 20px;
  }
  .post {
    margin-top: 300px;
    font-size: 80%;
  }
}
