.wrapper {
  display: flex;
  text-align: left;
  margin-bottom: 4%;
  perspective: 1000px;
}

.gold-bar {
  width: 225px;
  height: 6px;
  background-color: #d5b060;
  border: none;
  padding: 0;
  margin: 0;
}

.gov_name {
  margin: 0;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  color: #d5b060;
  font-weight: 300;
  padding: 0;
  width: 300px;
}

.main-sub-header {
  color: #d5b060;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin: 0;
  font-size: 22px;
}

.name-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 150px;
  margin: 20px 0 0 0px;
  /* margin-left:4.5%; */

  /* top: .8%; */
}

.rotate-logo {
  transform-style: preserve-3d;
  /* --clr: rgb(247, 197, 159);
  --clr-alpha: rgb(247, 197, 159, 0.1); */
  animation: spinner 3s ease forwards;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
    transform: rotate(360deg) rotateX(385deg) rotateY(25deg);
  }

  100% {
    transform: rotate(0deg) rotateX(-0deg) rotateY(0def);
  }
}

@media (max-width: 1100px) {
  .gold-bar {
    width: 200px;
    height: 3px;
  }

  .gov_name {
    font-size: 22px;
  }

  .main-sub-header {
    font-size: 17px;
  }

  .name-div {
    margin-left: 0px;
    justify-content: center;
    grid-gap: 15px;
  }
}

@media (max-width: 850px) {
  .name-div {
    margin-top: 10px;
  }
}
@media (max-width: 700px) {
  .gold-bar {
    width: 180px;
    height: 3px;
  }

  .gov_name {
    font-size: 22px;
  }

  .main-sub-header {
    font-size: 16px;
  }

  .name-div {
    margin-left: -20px;

    justify-content: center;
  }
}

@media (max-width: 500px) {
  .name-div {
    margin-left: -60px;
    margin-top: 0px;
    gap: 15px;
  }

  .gold-bar {
    width: 125px;
  }

  .gov_name {
    font-size: 18px;
  }
  .main-sub-header {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .name-div {
    margin-left: -60px;
    margin-top: -10px;
  }

  .gold-bar {
    width: 120px;
  }

  .main-sub-header {
  }
}
