@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.gn-landingpage-wrapper {
  background-image: linear-gradient(to bottom, #4a4e69, #c9ada7);
  height: 100vh;
  width: 100%;
}

.global-news-title {
  margin: 0;
  font-weight: 400;
  font-size: 31.25px;
  color: #f2e9e4;
  padding: 50px 0 0 60px;
}

.gn-copy-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex-direction: column;
}

.gn-main-copy {
  font-weight: 400;
  font-size: 39.06px;
  color: #f2e9e4;
}

.gn-sub-copy {
  font-weight: 400;
  font-size: 25px;
  color: #f2e9e4;
}

.gn-login-wrapper {
  height: 450px;
  width: 450px;
  border-radius: 32px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.gn-login-wrapper .gn-traditional-logins-wrapper {
  display: flex;
  gap: 20px;
}
.gn-login-wrapper .gn-traditional-logins-wrapper button {
  background-color: #4a4e69;
  color: #f2e9e4;
  font-weight: 700;
  border: none;
  width: 150px;
  height: 50px;
  border-radius: 16px;
  cursor: pointer;
}
.gn-login-wrapper p {
  color: #f2e9e4;
  font-size: 20px;
}

.gn-google-link {
  background-color: #4a4e69;
  padding: 8px;
  border-radius: 32px;
  border: 2px solid #f2e9e4;
  font-weight: 500;
  letter-spacing: -1px;
  cursor: pointer;
  margin-left: 5px;
}

.gn-graphic {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

@media (max-width: 992px) {
  .gn-copy-wrapper {
    width: 600px;
    margin: auto;
  }
  .gn-main-copy {
    width: 80%;
    text-align: center;
  }
  .gn-sub-copy {
    width: 80%;
    text-align: center;
  }
  .gn-login-wrapper {
    height: 400px;
  }
}
@media (max-width: 768px) {
  .gn-copy-wrapper {
    width: 500px;
    margin: auto;
  }
  .gn-main-copy {
    font-size: 31.25px;
  }
  .gn-sub-copy {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .gn-landingpage-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .global-news-title {
    text-align: center;
    padding-left: 0;
  }
  .gn-main-copy {
    font-size: 25px;
  }
  .gn-sub-copy {
    font-size: 16px;
    color: #fbfbfb;
  }
  .gn-login-wrapper {
    width: 300px;
    height: 300px;
    font-size: 16px;
  }
  .gn-login-wrapper p {
    font-size: 16px;
  }
  .gn-login-btns {
    width: 100px !important;
  }
  .gn-copy-wrapper {
    width: 100%;
  }
}
.gn-authpage-wrapper {
  background-image: linear-gradient(to bottom, #4a4e69, #c9ada7);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gn-auth-form-wrapper {
  background-color: #22223b;
  height: 450px;
  width: 450px;
  border-radius: 32px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0;
}

.gn-auth-title {
  font-weight: 400;
  font-size: 25px;
  color: #f2e9e4;
  margin: 0;
}

.gn-auth-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.gn-auth-form button {
  background-color: #4a4e69;
  color: #f2e9e4;
  font-weight: 700;
  border: none;
  width: 150px;
  height: 50px;
  border-radius: 16px;
  cursor: pointer;
}

.gn-form-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.gn-form-section label {
  font-weight: 400;
  font-size: 16px;
  color: #f2e9e4;
}
.gn-form-section input {
  background-color: #c9ada7;
  border: none;
  border-radius: 6px;
  height: 25px;
  width: 200px;
}

.gn-success-msg {
  background-color: #c9ada7;
  font-weight: 500;
  padding: 5px;
  border-radius: 12px;
  width: 90%;
  margin: auto;
}

@media (max-width: 576px) {
  .gn-auth-form-wrapper {
    width: 300px;
  }
}

