@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
:root {
  --card-width: 250px;
  --card-border-radius: 16px;
  --row-increment: 5px;
  --card-small: 15;
  --card-medium: 22;
  --card-large: 25;
}

.bulletin-component-wrapper {
  height: 570px;
  width: 500px;
  background-color: #22223B;
  margin-top: 100px;
  border-radius: 12px;
  padding: 5px;
  overflow: scroll;
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--card-width));
  grid-template-rows: var(--row-increment);
  justify-content: flex-start;
}

.card {
  height: auto;
  padding: 0px;
  margin: 5px;
  border-radius: 12px;
  background-color: #C9ADA7;
}

.card-small {
  grid-row-end: span var(--card-small);
}

.card-medium {
  grid-row-end: span var(--card-medium);
}

.card-large {
  grid-row-end: span var(--card-large);
}

.bulletin-component-wrapper-no-data {
  height: 570px;
  width: 500px;
  background-color: #22223B;
  margin-top: 100px;
  border-radius: 12px;
  padding: 5px;
  overflow: scroll;
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-bulletin {
  color: #C9ADA7;
  text-align: center;
  vertical-align: middle;
}

.gn-box-content {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
}
.gn-box-content p {
  padding-left: 10px;
  padding-right: 10px;
}

.gn-box-content:hover {
  transform: scale(1.05);
}

.gn-article-title {
  color: #22223B;
  font-weight: 500;
  margin-bottom: 10px;
}

.gn-publisher-div {
  display: flex;
  align-items: center;
  background-color: #4A4E69;
  height: 100%;
  border-radius: 12px;
}

.gn-publisher {
  color: #C9ADA7;
}

@media (max-width: 1050px) {
  .bulletin-component-wrapper {
    margin-top: 50px;
  }
  .bulletin-component-wrapper-no-data {
    margin-top: 50px;
  }
}
@media (max-width: 576px) {
  .bulletin-component-wrapper {
    width: 300px;
    margin-bottom: 20px;
  }
  .bulletin-component-wrapper-no-data {
    width: 300px;
    margin-bottom: 20px;
  }
  :root {
    --card-width: 150px;
  }
}

