@use "globalstyles";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

$intro-page-color: linear-gradient(
  to bottom,
  map-get(globalstyles.$palette, light-gray),
  map-get(globalstyles.$palette, light-pink)
);

@mixin intro-page-box {
  height: 450px;
  width: 450px;
  border-radius: 32px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

@mixin gn-login-btns {
  background-color: map-get(globalstyles.$palette, light-gray);
  color: map-get(globalstyles.$palette, lightest);
  font-weight: map-get(globalstyles.$font-weights, bold);
  border: none;
  width: 150px;
  height: 50px;
  border-radius: 16px;
  cursor: pointer;
}

.gn-landingpage-wrapper {
  background-image: $intro-page-color;
  padding-bottom: 100px;
}

.gn-landingpage-wrapper h1,
h2 {
  margin: 0;
}

.gn-landingpage-wrapper section {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.global-news-title {
  margin-top: 90px;
  font-weight: map-get(globalstyles.$font-weights, regular);
  font-size: map-get(globalstyles.$font-sizes, large);
  color: map-get(globalstyles.$palette, lightest);
}
.gn-copy-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex-direction: column;
}
.gn-main-copy {
  font-weight: map-get(globalstyles.$font-weights, regular);
  font-size: map-get(globalstyles.$font-sizes, largest);
  color: map-get(globalstyles.$palette, lightest);
}

.gn-sub-copy {
  font-weight: map-get(globalstyles.$font-weights, regular);
  font-size: map-get(globalstyles.$font-sizes, medium);
  color: map-get(globalstyles.$palette, lightest);
}

.gn-main-copy {
  padding-top: 30px;
}

.gn-google-link {
  background-color: map-get(globalstyles.$palette, light-gray);
  padding: 8px;
  border-radius: 32px;
  border: 2px solid map-get(globalstyles.$palette, lightest);
  font-weight: map-get(globalstyles.$font-weights, semi-bold);
  letter-spacing: -1px;
  cursor: pointer;
  margin-left: 5px;
  color: white;
}

.gn-graphic {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
.lndpg-btns-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 30px;
}
.lndpg-btns-wrapper button {
  background-color: rgb(74, 78, 105);
  border: 2px solid white;
  color: white;
  border-radius: 20px;
  padding: 10px 20px;
  width: 100px;
  font-size: 16px;
  cursor: pointer;
}

.ellipse {
  height: 400px;
  width: 400px;
  border-radius: 100%;
  border: 1px solid white;
  animation-duration: 10s;
  animation-name: rotation;
  animation-iteration-count: infinite;
  animation-direction: normal;
  // overflow: hidden;
  background: radial-gradient(
    circle at 100%,
    #53556e,
    #827884 50%,
    #ad989a 75%,
    #70163c 75%
  );
}

.point {
  height: 50px;
  width: 50px;

  background: radial-gradient(
    circle at 100%,

    #827884 50%,
    #827884 45%,
    #22223b
  );
  border-radius: 100%;
  overflow: hidden;
  transform: rotate(180deg);
}

.stem {
  height: 60px;
  width: 5px;
  background-color: #22223b;
  margin-top: -20px;
  margin-left: 20px;
  box-shadow: 20px 10px 20px #22223b;
}

.boxes {
  width: 70%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media (max-width: 992px) {
  .gn-copy-wrapper {
    width: 600px;
    margin: auto;
  }

  .gn-main-copy {
    width: 80%;
    text-align: center;
  }

  .gn-sub-copy {
    width: 80%;
    text-align: center;
  }
}

@media (max-width: 820px) {
  .gn-copy-wrapper {
    width: 500px;
    margin: auto;
  }

  .gn-main-copy {
    font-size: map-get(globalstyles.$font-sizes, large);
  }

  .gn-sub-copy {
    font-size: map-get(globalstyles.$font-sizes, small);
  }
}

@media (max-width: 576px) {
  .ellipse {
    height: 200px;
    width: 200px;
  }

  .point {
    height: 25px;
    width: 25px;
  }

  .stem {
    height: 32px;
    width: 2.5px;
    margin-left: 11px;
  }
  .gn-landingpage-wrapper {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .gn-landingpage-wrapper section {
    margin-top: 0;
  }
  .global-news-title {
    text-align: center;
    padding-left: 0;
  }
  .gn-main-copy {
    font-size: map-get(globalstyles.$font-sizes, medium);
  }

  .gn-sub-copy {
    font-size: map-get(globalstyles.$font-sizes, x-small);
    color: #fbfbfb;
  }

  .gn-copy-wrapper {
    width: 100%;
  }
}
@media (max-height: 800px) {
  .ellipse {
    height: 300px;
    width: 300px;
  }
}

@media (max-height: 800px) and (max-width: 576px) {
  .ellipse {
    height: 200px;
    width: 200px;
  }

  .point {
    height: 25px;
    width: 25px;
  }

  .stem {
    height: 32px;
    width: 2.5px;
    margin-left: 11px;
  }
}
@media screen and (max-width: 1024px) and (max-height: 768px) {
  .ellipse {
    height: 170px;
    width: 170px;
  }

  .point {
    height: 25px;
    width: 25px;
  }
  .stem {
    height: 32px;
    width: 2.5px;
    margin-left: 11px;
  }
}
